import React from "react"
import styled from "styled-components"
import { Option } from './Select'

const StyledOption = styled.span`
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #007eff;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
  span:nth-child(1) {
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: 1px solid #c2e0ff;
    border-right: 1px solid rgba(0, 126, 255, 0.24);
    padding: 1px 5px 3px;
    &:hover {
      background-color: rgba(0, 113, 230, 0.08);
      color: #0071e6;
    }
  }
  span:nth-child(2) {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    cursor: default;
    padding: 2px 5px;
}
  }

`
const SelectedOption = ({ option, onClick }: { option: Option, onClick: () => void }) => {
  return (
    <StyledOption>
      <span onClick={(e) => {
        e.stopPropagation();
        onClick()
      }}>x</span>
      <span>{option.name}</span>
    </StyledOption>
  )
}

export default SelectedOption
