import { BannerStatus } from 'consts'
import { useFormik } from 'formik'
import { Banner } from 'types'
import * as Yup from 'yup'
import { Modal } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'
import _, { mapKeys } from 'lodash'
import convertBrandsIdToGroupBrandId from 'utils/convertBrandsIdToGroupBrandId'
import useMasterData from './useMasterData'
import { useMemo, useState } from 'react'
const { confirm } = Modal

export const validationSchema = Yup.object().shape({
  groups_brands: Yup.array()
    .of(Yup.string())
    .when('currentStatus', {
      is: (value) =>
        [
          BannerStatus.INSERTED,
          BannerStatus.PARTIALLY_INSERTED_BRANDS,
        ].includes(value),
      then: Yup.array().of(Yup.string()).required(),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),

  categories: Yup.array()
    .of(Yup.string())
    .when('currentStatus', {
      is: (value) =>
        [
          BannerStatus.INSERTED,
          BannerStatus.PARTIALLY_INSERTED_BRANDS,
        ].includes(value),

      then: Yup.array().of(Yup.string()).required(),
      otherwise: Yup.array().of(Yup.string()).nullable(),
    }),

  format: Yup.string().when('currentStatus', {
    is: (value) =>
      [BannerStatus.INSERTED, BannerStatus.PARTIALLY_INSERTED_BRANDS].includes(
        value,
      ),
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
})

export type FormValues = {
  groups_brands: string[] | null
  categories: string[] | null
  format: null | string
  is_promotion: boolean | null
  is_game: boolean | null
}

const confirmationMessage: Partial<ModalFuncProps> = {
  title: 'Warning',
  content:
    "Careful, you're trying to insert banner(s) without brands/categories , if you do so it/they will be moved to partialy inserted banners",
  okText: 'Yes',
  okType: 'danger',
  cancelText: 'No',
  autoFocusButton: 'ok',
  getContainer() {
    const div = document.createElement('div')
    div.id = 'confirmation-modal'
    document.body.appendChild(div)
    return div
  },
  okButtonProps: {
    tabIndex: 1,
  },
  cancelButtonProps: {
    tabIndex: 2,
  },
}
const collectiveConfirmationMessage: Partial<ModalFuncProps> = {
  title: 'Warning',
  content:
    "Careful, you're trying to insert selected banners with the same values.",
  okText: 'Yes',
  okType: 'danger',
  cancelText: 'No',
  autoFocusButton: 'ok',
  getContainer() {
    const div = document.createElement('div')
    div.id = 'confirmation-modal'
    document.body.appendChild(div)
    return div
  },
  okButtonProps: {
    tabIndex: 1,
  },
  cancelButtonProps: {
    tabIndex: 2,
  },
}
const useForm = (banners: Banner[] = [], onInsert: any, countries: any[]) => {
  const { brands } = useMasterData(countries)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const brandsHMByName = useMemo(
    () =>
      mapKeys(brands, (brand) => {
        const originalName = brand.originalName.trim().toLowerCase() as string

        if (['other', 'others'].includes(originalName)) {
          return 'others'
        }
        return originalName.trim().toLowerCase()
      }),
    [brands],
  )
  const brandsHMById = useMemo(
    () => mapKeys(brands, (brand) => brand.id),
    [brands],
  )

  const handleFormikSubmit = async (values: any, f: any) => {
    const onOk = async (gb?: any) => {
      // only send non null values in the case of inserted banners
      const payload = banners.map((banner) => {
        const fields = {
          id: banner.id,
          image_hash_id: banner.image_hash_id,
          retailer_id: banner.retailer_id,
          currentStatus: banner.status,
          ...values,
        }
        return [
          BannerStatus.INSERTED,
          BannerStatus.PARTIALLY_INSERTED_BRANDS,
        ].includes(banner.status)
          ? _.pickBy(fields, (v, k) => {
            if (Array.isArray(v) && v.length === 0) {
              return false
            }
            return v !== null
          })
          : fields
      })
      await onInsert(
        payload.map((p) => {
          let groups_brands = gb ?? p.groups_brands
          if (groups_brands) {
            groups_brands = convertBrandsIdToGroupBrandId(groups_brands) as any
            groups_brands = groups_brands.map((_p: any) => {
              const [group_id, brand_id] = _p
              const brand = brandsHMById[`${group_id}-${brand_id}`]
              return [group_id, { name: brand?.name, brand_id }]
            })
          }
          return {
            id: banner.id,
            image_hash_id: banner.image_hash_id,
            retailer_id: banner.retailer_id,
            currentStatus: banner.status,
            ...p,
            groups_brands,
          }
        }),
      )
      f.resetForm()
      setIsModalVisible(false)
    }

    if (!(values.categories && values.categories.length)) {
      setIsModalVisible(true)
      confirm({
        ...confirmationMessage,
        onOk: () => onOk(),
      })
    } else if (!(values.groups_brands && values.groups_brands.length)) {
      const groups_brands = brandsHMByName['others']
      if (!(groups_brands && groups_brands.id)) {
        setIsModalVisible(true)
        confirm({
          ...confirmationMessage,
          onOk: () => onOk(),
        })
      } else {
        await onOk([groups_brands.id])
      }
    } else if (banners.length > 1) {
      setIsModalVisible(true)
      confirm({
        ...collectiveConfirmationMessage,
        onOk: () => onOk(),
      })
    } else {
      await onOk()
    }
  }

  const [banner] = banners


  const initialValues: any =
    banners.length > 1
      ? {
        categories: [],
        groups_brands: []
      }
      : banner
        ? {
          categories: banner.categories,
          groups_brands: banner.groups_brands,
          format: banner.format,
          is_promotion: banner.is_promotion,
          is_game: banner.is_game,
          currentStatus: banner.status,
        }
        : {
          categories: [],
          groups_brands: []
        }

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleFormikSubmit,
  })
  return { isModalVisible, setIsModalVisible, formik }
}

export default useForm
