import React from "react"
import styled from "styled-components";

const DropdownToggle = styled.div`
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  borderRadius: 4px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 100%
  min-height: 40px;
`

export default DropdownToggle;
