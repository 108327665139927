import React, { forwardRef, useEffect, useRef } from 'react';
import styled from "styled-components";


const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
`;

type TProps = {
  value: string,
  onChange: (value: string) => void,
  onRemoveElement: () => void,
  onSearchClick: () => void
  onNavigate: (top: boolean) => void
  onSubmit: () => void
  autoFocus?: boolean
}

const SearchInput = forwardRef<HTMLInputElement, TProps>(({ value, onChange, onRemoveElement, onSearchClick, onNavigate, onSubmit, autoFocus }, ref) => {
  return <Input
    ref={ref}
    type="text"
    placeholder="Search..."
    autoFocus={autoFocus}
    value={value}
    tabIndex={1}
    onClick={(e) => {
      e.stopPropagation()
      onSearchClick()
    }}
    onKeyDown={(e) => {
      e.stopPropagation()
      const target: any = e.target
      if (target.value === "" && e.keyCode === 8) {
        onRemoveElement()
      }
      if ([38, 40].includes(e.keyCode)) {
        onNavigate(e.keyCode === 38)
      }
      if (e.keyCode == 13) {
        e.preventDefault()
        onSubmit()
      }
    }}
    onChange={(e) => {
      onChange(e.target.value)
    }}
  />
})




export default SearchInput;
